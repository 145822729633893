import axios from 'axios'
// import { Notify, Toast } from 'vant'
import router from '../router'
import web from 'utils/common'
import { ElMessage } from 'element-plus'

export function request (config) {
  const instance = axios.create({
    baseURL: web.baseURL,
    timeout: 5000
  })

  // 请求拦截
  instance.interceptors.request.use(config => {
    // 如果有一个接口需要认证才可以访问，就在这统一设置
    const token = window.localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = token
      // config.headers['Access-Token'] = token
    }
    // 直接放行
    return config
  }, err => {
    // alert("当前发出的网络请求出现了致命的错误");
    ElMessage.error('当前发出的网络请求出现了致命的错误')
    return Promise.reject(err)
  })

  // 响应拦截
  instance.interceptors.response.use(res => {
    //   console.log(res)
    return res.data ? res.data : res
  }, err => {
    // 如果有需要授权才可以访问的接口， 统一去login授权
    if (err.response.status === '-1') {
      // Toast.fail('请先登录')
      ElMessage.error('请先登录')
      router.push({ path: '/login' })
    }

    // 如果有错误，这里面会去处理，显示错误信息
    // Notify(err.response.data.errors[Object.keys(err.response.data.errors)[0]][0])
  })

  return instance(config)
}
